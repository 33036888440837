<template>
  <svg
    width="7"
    height="12"
    viewBox="0 0 7 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.82422 6.56641C7 6.39062 7 6.14453 6.82422 5.96875L1.69141 0.765625C1.51562 0.589844 1.23438 0.589844 1.09375 0.765625L0.390625 1.46875C0.214844 1.60938 0.214844 1.89062 0.390625 2.06641L4.53906 6.25L0.390625 10.4688C0.214844 10.6445 0.214844 10.8906 0.390625 11.0664L1.09375 11.7695C1.23438 11.9453 1.51562 11.9453 1.69141 11.7695L6.82422 6.56641Z"
      fill="currentColor"
    />
  </svg>
</template>
